import { DateTime as DTL } from 'luxon'
import { DateTime, Prices, SpreadsheetEntry, teamIDs } from "./types"

/**
 * Scroll to element
 * @param target
 * @param defaultOffset
 * @returns
 */
const scrollToRef = (target: React.RefObject<HTMLDivElement>, defaultOffset = 100): void => {
	if (target.current) {
		window.scrollTo({
			top: target.current.offsetTop - defaultOffset,
			left: 0,
			behavior: "smooth"
		})
	}
}

export default scrollToRef

// In case you query the DOM in your useEffect hooks
// or when it's too hard does not make any sense to connect models and views using props/state
export const scrollToHTMLElement = (target: HTMLElement | null, offset = 0) => {
	if (target) {
		window.scrollTo({
			top: target.offsetTop + offset,
			left: 0,
			behavior: "smooth"
		})
	}
}

/**
 * Sort an array of objects by specific property (key)
 * @param haystack array of objects to sort
 * @param prop sortable property
 * @returns sorted array of objects
 */
export const sortByProp = (haystack: any[], prop: string): any[] => {
	return haystack.sort(function (a, b) {
		const nameA = a[prop].toLowerCase()
		const nameB = b[prop].toLowerCase()
		if (nameA < nameB) {
			return -1
		}
		if (nameA > nameB) {
			return 1
		}
		return 0
	})
}

export const transformData = (raw: string[]): SpreadsheetEntry => {
	return {
		gsx$date: { $t: raw[0] ?? "" },
		gsx$time: { $t: raw[1] ?? "" },
		gsx$opponent: { $t: raw[2] ?? "" },
		gsx$city: { $t: raw[3] ?? "" },
		gsx$team: { $t: raw[4] ?? "" },
		gsx$ticketlink: { $t: raw[5] ?? "" },
		gsx$promo: { $t: raw[6] ?? "" },
		gsx$govx: { $t: raw[7] ?? "" },
		gsx$grouppricing: { $t: raw[8] ?? "" },
		gsx$corporateoffers: { $t: raw[9] ?? "" },
		gsx$specialoffers: { $t: raw[10] ?? "" },
		gsx$nbapartners: { $t: raw[11] ?? "" }
	}
}

export const sortByMonthOrder = (arr: DateTime[]): DateTime[] => {
	const desiredOrder = [
		"September",
		"October",
		"November",
		"December",
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August"
	]
	return arr.sort(function (a, b) {
		return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
	})
}

export const sortByPriceCategory = (arr: Prices[]): Prices[] => {
	const desiredOrder = [
		"Super - $10 to $55",
		"Saver - $15 to $75",
		"Value - $18 to $80",
		"Select - $25 to $105",
		"Elite - $35 to $160",
		"Premium - $65 to $255"
	]
	return arr.sort(function (a, b) {
		return desiredOrder.indexOf(a.id) - desiredOrder.indexOf(b.id)
	})
}

export const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const trimText = (
	str: string,
	maxLength = 100,
	{ side = "end", ellipsis = "..." } = {}
): string => {
	if (str.length > maxLength) {
		switch (side) {
			case "start":
				return ellipsis + str.slice(-(maxLength - ellipsis.length))
			case "end":
			default:
				return str.slice(0, maxLength - ellipsis.length) + ellipsis
		}
	}
	return str
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
export const groupBy = (objectArray: any, property: any) => {
	return objectArray.reduce(function (acc: any, obj: any) {
		let key = obj[property]
		if (!acc[key]) {
			acc[key] = []
		}
		acc[key].push(obj)
		return acc
	}, {})
}

/**
 * Format a number to USD
 * @param value number to format to USD
 * @param showDecimals show cents - boolean - default: false
 * @returns USD formatted currency
 */
export const formatToUSD = (value: number, showDecimals = false) =>
	new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: showDecimals ? 2 : 0,
		maximumFractionDigits: showDecimals ? 2 : 0 // Add this line to restrict decimals
	}).format(value)

export const findTeamLogo = (teamAbbreviation: string) => {
	return `https://cdn.nba.com/logos/nba/${teamIDs[teamAbbreviation as keyof typeof teamIDs]
		}/primary/L/logo.svg`
}

// Function to filter and split event strings based on the current date
export const filterEventStringArrays = (haystack: string[]): string[][] => {
	// Get the current date and time in the 'America/Chicago' timezone
	const curDate = DTL.local().setZone('America/Chicago')

	// Format the current date as a string in 'yy.MM.dd' format (e.g., "24.09.16" for September 16, 2024)
	const todayDateString = curDate.toFormat('yy.MM.dd')

	// Filter the event strings by comparing their date with the current date, and then split them
	return haystack
		// Filter the events: keep only events whose date (after the '###' delimiter) is in the future
		.filter(value => todayDateString < value.split('###').slice(-1)[0])
		// Split each event string by the '###' delimiter into a list of values
		.map(value => value.split('###'))
}
