import { useEffect, useState } from "react"
import {
  formstackAPIEndpointFormSubmission, formstackAPIEndpointGetFormDetails,
} from "../../../../etc/settings"
import {
  IFormStackParams,
  IFormstackFormDetailsAPIResponse,
  IFormStackFormState,
  FormstackFormStatus,
  IFormstackFormField,
  IFormstackFormDetailsWithUserData,
} from "../../../../etc/types"
import prepareSubmissionData, {
  escapeHtml,
  initialFormState,
  selectPlaceholderText,
} from "../etc/functions"
import {
  FormstackView,
  FormSubmissionDeactivated,
  FormSubmissionFailed,
  FormSubmitted,
  FormSubmitting,
} from "../views"
import { formstackAPIStatusCodes } from "./staticData"



const Formstack: React.FC<IFormStackParams> = (p) => {
  // Incoming parameters
  const { formID, options } = p

  // Store form metadata (or details) that we get from Formstack API
  const [formDetails, setFormDetails] = useState<IFormstackFormDetailsWithUserData>()
  // Initialize form state
  const [formState, setFormState] = useState<IFormStackFormState>({
    ...initialFormState,
    formStatus: options?.initialState
      ? options?.initialState
      : FormstackFormStatus.not_submitted,
  })

  // 1: Get form metadata (or details) from Formstack API
  // See https://developers.formstack.com/reference/api-overview
  useEffect(() => {

    setFormState({
      ...formState,
      formStatus: FormstackFormStatus.loading,
    });

    (async () => {

      try {

        const response = await fetch(formstackAPIEndpointGetFormDetails, {
          method: "POST",
          body: escapeHtml(JSON.stringify({ formID }))
        })

        const data = await response.json() as IFormstackFormDetailsAPIResponse

        const details = {
          ...data.body,
          user_agent: data?.aws_headers["User-Agent"],
          remote_addr: data?.aws_headers["CloudFront-Viewer-Address"]?.split(':')[0],
          longitude: data?.aws_headers["CloudFront-Viewer-Longitude"],
          latitude: data?.aws_headers["CloudFront-Viewer-Latitude"]
        }

        switch (response.status) {

          case 200:
            setFormDetails(details)
            setFormState({
              ...formState,
              formStatus: data.body.inactive
                ? FormstackFormStatus.deactivated
                : options?.initialState
                  ? options.initialState
                  : FormstackFormStatus.not_submitted,
            })
            break

          default:
            // API Level errors but runtime is fine
            setFormState({
              ...formState,
              formStatus: FormstackFormStatus.submission_failed,
            })

        }

        console.log(
          "##### FormstackGetFormDetails: " +
          formstackAPIStatusCodes[response.status] +
          " #####"
        )
      } catch (error) {
        // Runtime Level errors
        console.error(error)
        setFormState({
          ...formState,
          formStatus: FormstackFormStatus.submission_failed,
        })
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formID])

  // 2: Form interactions callback
  const handleFormFieldChange = (field: IFormstackFormField, value: any) => {
    // Heads up, multiple state mutations below.
    // Make sure you use the setFormState() only once at the end of function
    const newState = { ...formState }

    const { id: fieldID } = field

    // Merge in field value using its fieldID as key
    newState.values = {
      ...formState.values,
      [fieldID]: value,
    }
    // Remove field id from errors as there is some value in it since we are here
    newState.errors = [...newState.errors.filter((e) => e !== fieldID)]

    // Apply all state modifications in a single call
    setFormState(newState)
  }

  // 3: Form submission handler: if validation fails, user will have to go back to p.2 and fill in fields
  const submitHandler = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const tempErr: string[] = []

    // Digging in formDetails metadata received from Formstack API
    formDetails?.fields.forEach((field) => {
      // If this field is required, get its value
      if ("1" === String(field.required)) {
        switch (field.type) {
          // For address field type we must loop over each one
          case "address":
          case "name":
            field.visible_subfields?.forEach((subfieldKey) => {
              // This is to make TypeScript happy about it
              const id = subfieldKey as keyof typeof formState.values

              const formFieldValue = formState.values[id] as string

              if (subfieldKey.toLowerCase() !== "address2") {
                if (
                  "" === formFieldValue ||
                  undefined === formFieldValue ||
                  null === formFieldValue ||
                  "" === formFieldValue.trim() ||
                  selectPlaceholderText === formFieldValue
                ) {
                  tempErr.push(subfieldKey)
                }
              }
            })

            break

          default:
            // This is to make TypeScript happy about it
            const id = field.id.slice(0) as keyof typeof formState.values

            const formFieldValue = formState.values[id] as string

            // Populating tempErr with the field id as its value cannot be empty
            // according to its metadata
            switch (field.type) {
              case "checkbox":
                let atLeastOneChecked = false
                let notSelected: string[] = []

                Object.keys(field.options).forEach((k, idx) => {
                  const optionFieldKey = (field.id +
                    "-" +
                    k) as keyof typeof formState.values
                  if (
                    undefined === formState?.values[optionFieldKey] ||
                    "" === formState?.values[optionFieldKey]
                  ) {
                    notSelected.push(optionFieldKey)
                  } else {
                    atLeastOneChecked = true
                  }
                })

                if (false === atLeastOneChecked) {
                  notSelected.forEach((n) => tempErr.push(n))
                }

                break

              default:
                if (
                  "" === formFieldValue ||
                  undefined === formFieldValue ||
                  null === formFieldValue ||
                  "" === formFieldValue.trim() ||
                  selectPlaceholderText === formFieldValue
                ) {
                  tempErr.push(field.id)
                }
            }
        }
      }
    })

    // Replace errors in state with its modified version
    setFormState({
      ...formState,
      errors: tempErr,
    })

    // Submit Form values to Formstack API
    if (!tempErr.length && formDetails) {
      postSubmission(formID, formState.values, formDetails)
    }
  }

  // 4. Process form submission data
  const postSubmission = (
    formID: number,
    data: Object,
    formDetails: IFormstackFormDetailsWithUserData
  ) => {

    try {

      const payload = {
        formID,
        formData: prepareSubmissionData(data, formDetails),
      }

      setFormState({
        ...formState,
        formStatus: FormstackFormStatus.submitting,
      })

      options?.finalCallback && options.finalCallback()

      fetch(formstackAPIEndpointFormSubmission, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: escapeHtml(JSON.stringify(payload))
      })
        .then(r => r.json())
        .then(res => {
          // Using API level statusCode instead of fetch native .status to check
          // the result of form submission
          // https://developers.formstack.com/reference/form-id-submission-post
          if (201 === res.statusCode) {
            setFormState({
              ...initialFormState,
              formStatus: FormstackFormStatus.submitted,
            })
          } else {
            // API level error
            setFormState({
              ...formState,
              formStatus: FormstackFormStatus.submission_failed,
            })
          }
        })

    } catch (error) {
      // Runtime level error
      console.error(error)
      setFormState({
        ...formState,
        formStatus: FormstackFormStatus.submission_failed,
      })
    }
  }

  switch (formState.formStatus) {
    case FormstackFormStatus.submission_failed:
      return options?.submissionFailedMessage ? (
        options.submissionFailedMessage
      ) : (
        <FormSubmissionFailed />
      )

    case FormstackFormStatus.submitting:
    case FormstackFormStatus.loading:
      return options?.submittingMessage ? (
        options.submittingMessage
      ) : (
        <FormSubmitting />
      )

    case FormstackFormStatus.submitted:
      return options?.submittedMessage ? (
        options.submittedMessage
      ) : (
        <FormSubmitted />
      )

    case FormstackFormStatus.deactivated:
      return options?.formDeactivatedMessage ? (
        options.formDeactivatedMessage
      ) : (
        <FormSubmissionDeactivated />
      )

    default:
      return formDetails?.fields.length ? (
        <FormstackView
          {...{
            formDetails,
            formState,
            callback: handleFormFieldChange,
            submitHandler,
            options,
          }}
        >
          {p.children}
        </FormstackView>
      ) : options?.formDeactivatedMessage ? (
        options.formDeactivatedMessage
      ) : (
        <FormSubmissionDeactivated />
      )
  }
}

export default Formstack
