import { s3BaseURL } from "../../etc/settings"

const DevNav: React.FC<{ type: string }> = ({type}) => (
  <picture>
    <source
      media="(min-width: 992px)"
      srcSet={s3BaseURL + "assets/img/" + type + "-desktop.jpg"}
    />
    <img
      className="w-100"
      src={s3BaseURL + "assets/img/" + type + "-mobile.jpg"}
      alt=""
    />
  </picture>
)

export default DevNav
