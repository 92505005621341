import { IFormStackAPIAPIStatusCodes } from "../../../../etc/types"

export const formstackAPIStatusCodes:IFormStackAPIAPIStatusCodes = {
  200: "OK - The request was handled without any errors",
  201: "Created - A new resource was created",
  400: "Bad Request - The request URI was invalid",
  401: "Unauthorized - Valid OAuth2 credentials were not supplied",
  403: "Forbidden - The current user does not have access to this method",
  404: "Not Found - The resource requested could not be found",
  405: "Method Not Allowed - The requested method does not exist",
  415: "Unsupported Media Type - A valid media type (JSON, XML, HTTP URL encoded) was not used",
  429: "Too Many Requests - The current user has hit the daily rate limit",
  500: "Internal Server Error - An error occurred while processing the request",
}
