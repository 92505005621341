// @ts-nocheck
import React, { useEffect } from "react"
import { Button, Col, Form } from "react-bootstrap"
import { s3BaseURL } from "../../../../etc/settings"
import { IFormStackFormView } from "../../../../etc/types"
import actButton from "../../../partials/action-button/assets/css/index.module.scss"
import styles from "../assets/css/index.module.scss"
import { getFormFieldValue, selectPlaceholderText, getBase64 } from "../etc/functions"

export const FormstackView: React.FC<IFormStackFormView> = (p) => {
  const { formDetails, formState, callback, submitHandler, options } = p

  const { num_columns } = formDetails

  /**
   * See https://developers.formstack.com/reference/form-id-submission-post#file-uploads-and-signatures
   */
  const processUploadedFile = async (field: IFormstackFormField, e: HTMLInputEvent) => {

    await getBase64(e.target.files[0])
      .then(res => callback(field, res))
      .catch(err => console.log(err))

  };

  // Adds default value to formState when form loads
  useEffect(() => {
    formDetails.fields.forEach((field) => {
      Object.entries(field.options).forEach(([key, option]) => {
        const { value } = option;
        const optionFieldKey = `${field.id}-${key}`;

        if (field.default === value) {
          const newField = { ...field, id: optionFieldKey };
          callback(newField, value);
        }
      });
    });

    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form onSubmit={(e) => submitHandler(e)} className={styles.formstackForm}>
        <div className="row">
          {formDetails.fields
            .filter((f) => "0" === f.hidden)
            .map((field, idx) => {
              // Initialize build array
              const build = []

              let subfieldMultiplier = 1
              const { colspan } = field

              let bsColumns =
                false === options?.colspan
                  ? 12
                  : (12 / Number(num_columns)) * Number(colspan)

              // Multifield logic: normally we show all N child fields inline on desktops
              if (field.visible_subfields && field.visible_subfields.length > 1) {
                subfieldMultiplier = field.visible_subfields.length
                bsColumns /= subfieldMultiplier
              }

              switch (field.type) {
                case "select":
                  build.push(
                    <div key={idx} className={"col-12 col-md-" + bsColumns}>
                      <Form.Group className="mb-3" controlId={field.id}>
                        <Form.Label>
                          {field.label}
                          {field.required == 1 ? <sup>*</sup> : ""}
                        </Form.Label>
                        <Form.Select
                          aria-label={field.label}
                          value={getFormFieldValue(formState.values, field)}
                          isInvalid={
                            undefined !==
                            formState.errors.find((e) => e === field.id)
                          }
                          onChange={(e) => callback(field, e.currentTarget.value)}
                        >
                          {Array.isArray(field.options) &&
                            field.options.map((option, idx) =>
                              !idx && field.required == 1 && "" === option.value ? (
                                <option key={idx} value={selectPlaceholderText}>
                                  {selectPlaceholderText}
                                </option>
                              ) : (
                                <option key={idx} value={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  )
                  break
                case "textarea":
                  build.push(
                    <div key={idx} className={"col-12 col-md-" + bsColumns}>
                      <Form.Group className="mb-4" controlId={field.id}>
                        <Form.Label>
                          {field.label}
                          {field.required == 1 ? <sup>*</sup> : ""}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder=""
                          cols={field.cols}
                          rows={field.rows / 1.6}
                          value={getFormFieldValue(formState.values, field)}
                          isInvalid={
                            undefined !==
                            formState.errors.find((e) => e === field.id)
                          }
                          onChange={(e) => callback(field, e.currentTarget.value)}
                        />
                      </Form.Group>
                    </div>
                  )
                  break

                case "checkbox":
                  build.push(
                    <div key={idx} className={"col-12 col-md-" + bsColumns}>
                      <Form.Group className="mb-4" controlId={field.id}>
                        {(field.label.includes("Grizzlies Texts") || field.label.toLowerCase().includes("terms") || field.label.toLowerCase().includes("conditions")) && <Form.Label>
                          <strong>{field.label}</strong>
                        </Form.Label>}
                        {Object.entries(field.options).map(([key, option]) => {
                          const label = React.createElement('div', { dangerouslySetInnerHTML: { __html: `${field.options[key].label} ${field.required == 1 ? '<sup>*</sup>' : ''}` } })
                          const { value } = option
                          const optionFieldKey = `${field.id}-${key}`;
                          if (Array.isArray(field.options)) {
                            return (
                              <Form.Check
                                key={idx}
                                type="checkbox"
                                // id={`default-${field.id}-${value}`}
                                label={label}
                                value={value}
                                checked={
                                  value === formState?.values[optionFieldKey]
                                }
                                isInvalid={
                                  undefined !==
                                  formState.errors.find(
                                    (e) => e === optionFieldKey
                                  )
                                }
                                onChange={(e) =>
                                  callback(
                                    { ...field, id: optionFieldKey },
                                    undefined ===
                                      formState?.values[optionFieldKey] ||
                                      "" === formState?.values[optionFieldKey]
                                      ? e.currentTarget.value
                                      : ""
                                  )
                                }
                              />
                            )
                          }
                        })}
                      </Form.Group>
                    </div>
                  )

                  break

                case "radio":
                  build.push(
                    <div key={idx} className={"col-12 col-md-" + bsColumns}>
                      <Form.Group className="mb-4" controlId={field.id}>
                        <Form.Label>
                          {field.label}
                          {field.required == 1 ? <sup>*</sup> : ""}
                        </Form.Label>
                        {Object.keys(field.options).map((k, idx) => {
                          const key = k.slice(0) as keyof typeof field.options

                          if (Array.isArray(field.options)) {
                            return (
                              <Form.Check
                                key={idx}
                                type="radio"
                                label={field.options[key].label.slice(0)}
                                value={field.options[key].value}
                                checked={
                                  field.options[key].value ===
                                  getFormFieldValue(formState.values, field)
                                }
                                isInvalid={
                                  undefined !==
                                  formState.errors.find((e) => e === field.id)
                                }
                                onChange={(e) =>
                                  callback(field, e.currentTarget.value)
                                }
                              />
                            )
                          }
                        })}
                      </Form.Group>
                    </div>
                  )

                  break

                case "embed":
                case "richtext":
                case "section":
                  true !== options?.noRichText
                    ? build.push(
                      <div key={idx} className={"col-12 col-md-" + bsColumns}>
                        <Form.Group className="mb-3" controlId={field.id}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: field.section_text ?? "",
                            }}
                          ></div>
                        </Form.Group>
                      </div>
                    )
                    : null
                  break
                default:
                  if (
                    ("address" === field.type || "name" === field.type) &&
                    Array.isArray(field.visible_subfields)
                  ) {
                    field.visible_subfields.forEach((subfieldKey) => {
                      build.push(
                        <div
                          key={subfieldKey}
                          className={"col-12 col-md-" + bsColumns}
                        >
                          <Form.Group className="mb-3" controlId={field.id}>
                            <Form.Label className="text-capitalize">
                              {subfieldKey.toLowerCase() === "first" || subfieldKey.toLowerCase() === "last" ? subfieldKey + " name" : subfieldKey}
                              {field.required == 1 ? <sup>*</sup> : ""}
                            </Form.Label>
                            <Form.Control
                              // id={`${field.id}-${subfieldKey}`}
                              type={field.type}
                              placeholder=""
                              onChange={(e) =>
                                callback(
                                  { ...field, id: subfieldKey },
                                  e.currentTarget.value
                                )
                              }
                              value={getFormFieldValue(formState.values, {
                                ...field,
                                id: subfieldKey,
                              })}
                              isInvalid={
                                undefined !==
                                formState.errors.find((e) => e === subfieldKey)
                              }
                            />
                            <Form.Text className="text-muted">
                              {field.description}
                            </Form.Text>
                          </Form.Group>
                        </div>
                      )
                    })
                  } else if ("file" === field.type) {
                    const formFieldValue = getFormFieldValue(formState.values, field)
                    build.push(
                      <div key={idx} className={"col-12 col-md-" + bsColumns}>
                        <Form.Group className="mb-4 d-flex flex-column" controlId={field.id}>
                          <Form.Label className="text-nowrap">
                            {field.label}
                            {field.required == 1 ? <sup>*</sup> : ""}
                          </Form.Label>
                          <Form.Control
                            type={field.type}
                            placeholder=""
                            onChange={(e) =>
                              processUploadedFile(field, e)
                            }
                            value={''}
                            isInvalid={
                              undefined !==
                              formState.errors.find((e) => e === field.id)
                            }
                          />
                          <Form.Text className="text-muted">
                            {field.description}
                          </Form.Text>
                          {
                            formFieldValue.startsWith('data:image/') ? <img className="w-25 shadow-sm mt-2" alt="Image upload preview" src={getFormFieldValue(formState.values, field)} /> : '' !== formFieldValue ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-paperclip mt-2" viewBox="0 0 16 16">
                              <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                            </svg> : ''
                          }
                        </Form.Group>
                      </div>
                    )
                  } else {
                    build.push(
                      <div key={idx} className={"col-12 col-md-" + bsColumns}>
                        <Form.Group className="mb-3" controlId={field.id}>
                          <Form.Label>
                            {field.label}
                            {field.required == 1 ? <sup>*</sup> : ""}
                          </Form.Label>
                          <Form.Control
                            type={field.type}
                            placeholder=""
                            onChange={(e) =>
                              callback(field, e.currentTarget.value)
                            }
                            value={getFormFieldValue(formState.values, field)}
                            isInvalid={
                              undefined !==
                              formState.errors.find((e) => e === field.id)
                            }
                          />
                          <Form.Text className="text-muted">
                            {field.description}
                          </Form.Text>
                        </Form.Group>
                      </div>
                    )
                  }
              }

              return build
            })}
          {/**The div below is to prevent form actions showing on the same line when the last element above has col-6 etc.. */}
          <div className="col-12" />
          <Form.Group
            as={Col}
            controlId="submit"
            className="col col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start flex-nowrap mb-3 mb-md-0"
          >
            <Button
              variant="outline-light"
              type="submit"
              className={
                actButton.actionButton +
                " d-flex align-items-center justify-content-center"
              }
            >
              {formDetails.submit_button_title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-send d-inline-block ms-2"
                viewBox="0 0 16 16"
              >
                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
              </svg>
            </Button>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="privacy"
            className="col col-12 col-md-6 d-flex align-items-center justify-content-center  justify-content-md-end  flex-nowrap"
          >
            <a
              className="text-nowrap privacy"
              href="https://www.nba.com/privacy-policy"
              role="button"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Form.Group>
        </div>
      </Form>
      {p.children}
    </>
  )
}

export const FormMessageWrapper: React.FC = (props) => {
  return (
    <div
      className={
        "w-100 h-100 d-flex align-items-center justify-content-center flex-column text-center form-messages " +
        styles.formMessages
      }
    >
      {props.children}
    </div>
  )
}

export const FormSubmitting: React.FC = () => {
  return (
    <FormMessageWrapper>
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <p className="h6 py-4">Processing your request ...</p>
    </FormMessageWrapper>
  )
}

export const FormSubmissionFailed: React.FC = () => {
  return (
    <FormMessageWrapper>
      <p className="h6 pb-1">An unknown network error has occurred.</p>
      <p className="h6 pb-1">
        {" "}
        To reach the Memphis Grizzlies customer service team, please call{" "}
        <a href="tel:9012051234">901.205.1234</a>
      </p>
    </FormMessageWrapper>
  )
}

export const FormSubmitted: React.FC = () => {
  return (
    <FormMessageWrapper>
      <img
        className="h-auto"
        src={s3BaseURL + "assets/img/MG_Primary_Logo_Full_Color_RGB.png"}
        alt="Memphis Grizzlies logo"
      />
      <h3 className="h5 py-2">Thank you!</h3>
      <p className="h6 pb-1">The form was submitted successfully.</p>
    </FormMessageWrapper>
  )
}

export const FormSubmissionDeactivated: React.FC = () => {
  return (
    <FormMessageWrapper>
      <img
        className="h-auto"
        src={s3BaseURL + "assets/img/MG_Primary_Logo_Full_Color_RGB.png"}
        alt="Memphis Grizzlies logo"
      />
      <h3 className="h5 py-2">Form has been deactivated</h3>
      <p className="h6 pb-1">
        This form has been deactivated and is closed to any further submissions.
      </p>
    </FormMessageWrapper>
  )
}
