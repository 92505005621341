import moment from "moment-timezone"

// Prepare submission data to match Formstack API format

import {
  FormstackFormStatus,
  IFormstackFormDetailsWithUserData,
  IFormstackFormField,
  IFormStackFormState,
} from "../../../../etc/types"

// see https://developers.formstack.com/reference/form-id-submission-post
const prepareSubmissionData = (
  // it's a haystack
  input: any,
  formDetails: IFormstackFormDetailsWithUserData,
): Object => {
  const output = {
    user_agent: formDetails.user_agent,
    remote_addr: formDetails.remote_addr,
    latitude: formDetails.latitude,
    longitude: formDetails.longitude
  }

  // Fields with children, Address field for example: create parent object with children subfields
  // see https://developers.formstack.com/reference/form-id-submission-post
  formDetails.fields.forEach((field) => {
    if (field.visible_subfields) {
      const fieldWithChildren: any = {
        ["field_" + field.id]: {},
      }
      field.visible_subfields.forEach((vsKey) => {
        if (input.hasOwnProperty(vsKey)) {
          fieldWithChildren["field_" + field.id][vsKey] = input[vsKey]
        }
      })
      Object.assign(output, fieldWithChildren)
    } else {
      // Singular fields
      switch (field.type) {
        case "checkbox":
          const fieldWithChildren: any = {
            ["field_" + field.id]: {},
          }
          const selections: string[] = []
          Object.keys(field.options).forEach((k) => {
            const optionFieldKey = (field.id + "-" + k) as keyof typeof input
            if (input.hasOwnProperty(optionFieldKey)) {
              selections.push(input[optionFieldKey])
            }
          })
          fieldWithChildren["field_" + field.id] = selections.join()
          Object.assign(output, fieldWithChildren)
          break

        case "datetime":
          // Handler for hidden date fields with pre programmed timestamps
          if ("1" === field.hidden) {
            // As there's no strtotime in JS, need to strip out the default date manipulation string
            const re = /(NOW \+ |NOW \- | days)/gi

            // This will essentially look like this: '1' or '30'
            const dateManipulationString = field?.default?.replace(re, "")

            // https://momentjs.com/timezone/docs/#/using-timezones/converting-to-zone/
            const localTimeContext = moment().tz("America/Chicago")

            if (-1 === field.default?.search("-")) {
              //https://momentjs.com/docs/#/manipulating/add/
              localTimeContext.add(Number(dateManipulationString), "days")
            } else {
              //https://momentjs.com/docs/#/manipulating/subtract/
              localTimeContext.subtract(Number(dateManipulationString), "days")
            }

            //https://momentjs.com/docs/#/parsing/string-format/
            const dateString = localTimeContext.format("MM/DD/YY")

            Object.assign(output, { ["field_" + field.id]: dateString })
          }
          break

        case 'file':
          // https://developers.formstack.com/reference/form-id-submission-post#file-uploads-and-signatures
          // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
          // We need full file encoded blob in order to show image preview but for submissions the Data-URL
          // must be removed per Formstack API
          // The readAsDataURL method is used to read the contents of the specified Blob or File. When the read operation is finished, the readyState becomes DONE, and the loadend is triggered. At that time, the result attribute contains the data as a data: URL representing the file's data as a base64 encoded string.

          if (undefined != input[field.id]) {
            const split = input[field.id].split(',').pop()
            if (split) {
              Object.assign(output, { ["field_" + field.id]: split })
            }
          }

          break

        // all other field types
        default:
          Object.assign(output, { ["field_" + field.id]: input[field.id] })
      }
    }
  })

  return output
}

export const selectPlaceholderText = "- Select -"

// Init the default form state
export const initialFormState: IFormStackFormState = {
  errors: [],
  formStatus: FormstackFormStatus.not_submitted,
  values: {},
}

// Retrieve form field value from state
export const getFormFieldValue = (state: {}, field: IFormstackFormField) => {
  // This is to make TypeScript happy about it
  const id = field.id.slice(0) as keyof typeof state

  // Return the value of the field
  return state[id] ?? ""
}

export default prepareSubmissionData

export async function getBase64(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}

// https://github.com/angular/angular/blob/6dc43a475bf72a81ddc7d226f722488b04ed2582/packages/platform-browser/src/browser/transfer_state.ts#L12
export function escapeHtml(text: string): string {
  return text.replace(/</g, '\\u003c')
}