import { NextPage } from "next"
import Script from "next/script"
import { useEffect, useRef, useState } from "react"
import { team } from "../../../../etc/settings"
import { IPageWrapper } from "../../../../etc/types"
import DevNav from "../../../partials/DevNav"
import BlankPageWrapper from "../../blank-page-wrapper/views/BlankPageWrapper"

import styles from "../assets/css/index.module.scss"

const NBAPageWrapper: NextPage<IPageWrapper> = (props) => {
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [isProd, setIsProd] = useState(true)
  const ref1 = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (ref1) {
        setShowScrollTop(500 < window.pageYOffset)
      }
    })

    setIsProd(
      -1 ===
        ["next-builds.grizzliesapp.com"].indexOf(
          window.location.hostname
        )
    )
  }, [])

  return (
    <BlankPageWrapper {...props}>
      <div id="nba-styles" data-team={team} />
      {isProd ? (
        <div id="nba-nav" data-team={team} />
      ) : (
        <DevNav type="header" />
      )}

      <div className={styles.NBAPageContent}>
        {props.children}
        <button
          ref={ref1}
          className={`${styles.scrollTop} ${showScrollTop && styles.in}`}
          onClick={() =>
            window && window.scrollTo({ top: 0, behavior: "smooth" })
          }
        >
          Scroll to Top
        </button>
      </div>
      {isProd ? (
        <>
          <div id="nba-footer" data-team={team} />
          <Script src="https://www.nba.com/_teams/include/embed.js" />
        </>
      ) : (
        <DevNav type="footer" />
      )}
    </BlankPageWrapper>
  )
}

export default NBAPageWrapper
